import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import QuotationFormula from "./quotationFormula"
import QuotationWizard from "./quotationWizard"
import GAnalyticsService from "../services/gAnalyticsService"

class QuotationStep2 extends React.Component {
  state = {
    formules: [],
    dateAdhesion: "",
    age: "",
    ageConjoint: "",
    regimeAssuranceMal: "",
    regimeAssuranceMalConjoint: "",
  }
  tns
  props
  constructor(props) {
    super(props)
    this.props = props
  }
  stepBack(back, waiting) {
    waiting()
    back()
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    const { values, rA, formules, onContinue, setFieldValue } = this.props
    if (sessionStorage.getItem("devisStepOne")) {
      onContinue(JSON.parse(sessionStorage.getItem("devisStepOne")))
    }

    if (formules.length >= 1) {
      const codeGamme = formules[0].gamme.code
      "TNS" === codeGamme ? (this.tns = true) : (this.tns = false)
      setFieldValue("codeGamme", codeGamme)
      GAnalyticsService.sendEvent("Devis santé", "Tarification", codeGamme)
    }
    if (rA.data) {
      rA.data.map(e =>
        e.id === values.regimeAssuranceMal
          ? this.setState({ regimeAssuranceMal: e.libelle })
          : ""
      )
      if (values.regimeAssuranceMalConjoint) {
        rA.data.map(e =>
          e.id === values.regimeAssuranceMalConjoint
            ? this.setState({ regimeAssuranceMalConjoint: e.libelle })
            : ""
        )
      }
    }
    this.setState({ age: calculateAge(values.dateNaissance) })
    if (values.dateNaissanceConjoint) {
      this.setState({ ageConjoint: calculateAge(values.dateNaissanceConjoint) })
    }
    let date = new Date(Date.parse(values.debutContrat))
    let options = { day: "numeric", year: "numeric", month: "numeric" }
    this.setState({ dateAdhesion: date.toLocaleDateString("fr-FR", options) })
  }
  render() {
    const { formules, navigateBack, values, offer, waiting } = this.props
    return (
      <QuotationWizard.Step>
        <Container className="quotation container-step-2">
          <div className="quotation-step-2-header-with-button">
            <div>
              <h4>RAPPEL DE VOTRE SITUATION</h4>
            </div>
            <div className="quotation-btn">
              <button
                type="button"
                className="btn-quotation-step2"
                onClick={() => this.stepBack(navigateBack, waiting)}
              >
                Modifier
              </button>
            </div>
          </div>
          <Row className="quotation-step2-pres">
            <Col xs={12}>
              <span>
                Un bénéficiaire de {this.state.age} ans,{" "}
                {this.state.regimeAssuranceMal}
              </span>
            </Col>
            {values.conjointChecked ? (
              <Col xs={12}>
                <span>
                  Conjoint de {this.state.ageConjoint} ans,{" "}
                  {this.state.regimeAssuranceMalConjoint}
                </span>
              </Col>
            ) : (
              ""
            )}
            {values.nbEnfantsPlus1an ? (
              <Col xs={12}>
                <span>
                  {values.nbEnfantsPlus1an} enfant(s) de plus d'un an.
                </span>
              </Col>
            ) : (
              ""
            )}
            {values.nbEnfantsMoisn1an ? (
              <Col xs={12}>
                <span>
                  {values.nbEnfantsMoisn1an} enfant(s) de moins d'un an.
                </span>
              </Col>
            ) : (
              ""
            )}

            <Col xs={12}>
              <span>
                {" "}
                Date d'adhésion souhaitée: {this.state.dateAdhesion}{" "}
              </span>
            </Col>
          </Row>

          <div className="container-quotation-offer">
            <h5 className="quotation-offer-title">
              Offre(s) promotionnelle(s):
            </h5>
            <div className="m-bottom-50">
              {offer.data.map(e => {
                return (
                  <>
                    <span className="quotation-offer">{e.description}</span>
                    <br />
                  </>
                )
              })}
            </div>
          </div>

          <Row>
            <Col xs={12}>
              {formules.length >= 1 ? (
                <QuotationFormula
                  formules={formules}
                  button={false}
                  tns={this.tns}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Container>
      </QuotationWizard.Step>
    )
  }
}
export default QuotationStep2

function calculateAge(birthday) {
  birthday = Date.parse(birthday)
  var ageDifMs = Date.now() - birthday
  var ageDate = new Date(ageDifMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}
