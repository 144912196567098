import React from "react"
import { Field } from "formik"
import { Container, Row, Col } from "react-bootstrap"
import MgcTextField from "./mgcTextField"
import QuotationWizard from "./quotationWizard"
import MgcRadioToggleButtonGroup from "./mgcRadioToggleButtonGroup"
import MgcRadioToggleButton from "./mgcRadioToggleButton"
import QuotationAdresse from "./quotationAdresse"
import { hasNumber } from "../utils/regexTests"
import MgcPhoneField from "./mgcPhoneField"

class QuotationStep3 extends React.Component {
  componentDidMount() {
    const { validateForm } = this.props
    validateForm()
    window.scrollTo(0, 0)
  }
  render() {
    const { errors, dirty, values, touched, setFieldValue } = this.props
    console.log(values)
    console.log(errors)
    return (
      <QuotationWizard.Step>
        <Container className="quotation">
          <Row>
            <Col xs={12} className="quotation-step-3">
              <h4>COORDONNÉES PERSONNELLES</h4>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <span>Mes coordonnées</span>
            </Col>
            <Col xs={12} className="quotation-radio form-group">
              <MgcRadioToggleButtonGroup
                id="civilite"
                error={errors.civilite}
                touched={touched.civilite}
                withoutOffset={true}

              >
                <Field
                  component={MgcRadioToggleButton}
                  name="civilite"
                  id="homme"
                  label={"Monsieur"}
                />
                <Field
                  component={MgcRadioToggleButton}
                  name="civilite"
                  id="femme"
                  label={"Madame"}
                />
              </MgcRadioToggleButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <MgcTextField
                dirty={dirty}
                errors={errors}
                name="adherentNom"
                placeholder="NOM"
                className="form-control"
                withoutOffset={true}
                maxLength={30}
                onChange={e => {
                  if (!hasNumber(e.target.value)) {
                    setFieldValue(e.target.name, e.target.value)
                  }
                }}
              />
              <MgcTextField
                dirty={dirty}
                errors={errors}
                name="adherentPrenom"
                placeholder="Prénom"
                className="form-control"
                withoutOffset={true}
                maxLength={30}
                onChange={e => {
                  if (!hasNumber(e.target.value)) {
                    setFieldValue(e.target.name, e.target.value)
                  }
                }}
              />
            </Col>
          </Row>
          {values.conjointChecked &&
            <>
              <Row>
                <Col xs={12}>
                  <span>Mon conjoint</span>
                </Col>
                <Col xs={12} className="quotation-radio form-group">
                  <MgcRadioToggleButtonGroup
                    id="conjointCivilite"
                    error={errors.conjointCivilite}
                    touched={touched.conjointCivilite}
                    withoutOffset={true}

                  >
                    <Field
                      component={MgcRadioToggleButton}
                      name="conjointCivilite"
                      id="homme"
                      label={"Monsieur"}
                    />
                    <Field
                      component={MgcRadioToggleButton}
                      name="conjointCivilite"
                      id="femme"
                      label={"Madame"}
                    />
                  </MgcRadioToggleButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <MgcTextField
                    dirty={dirty}
                    errors={errors}
                    name="conjointNom"
                    placeholder="NOM"
                    className="form-control"
                    withoutOffset={true}
                    maxLength={30}
                    onChange={e => {
                      if (!hasNumber(e.target.value)) {
                        setFieldValue(e.target.name, e.target.value)
                      }
                    }}
                  />
                  <MgcTextField
                    dirty={dirty}
                    errors={errors}
                    name="conjointPrenom"
                    placeholder="Prénom"
                    className="form-control"
                    withoutOffset={true}
                    maxLength={30}
                    onChange={e => {
                      if (!hasNumber(e.target.value)) {
                        setFieldValue(e.target.name, e.target.value)
                      }
                    }}
                  />
                </Col>
              </Row>
            </>}
          <Row>
            <Col xs={12}>
              <h5>Je souhaite recevoir mon devis par</h5>
            </Col>
          </Row>
          <Row className="quotation-step-3-checkbox">
            <Col xs={12} >
              <Field
                className="check"
                name="mailChecked"
                type="checkbox"
                component="input"
                id="E-mail"
              />
              <label for="E-mail">E-mail</label>
              {values.mailChecked && (
                <>
                  <MgcTextField
                    dirty={dirty}
                    errors={errors}
                    name="email"
                    placeholder="Courriel"
                    className="form-control"
                    withoutOffset={true}
                  />
                  <MgcTextField
                    dirty={dirty}
                    errors={errors}
                    name="confirmEmail"
                    placeholder="Confirmation du courriel"
                    className="form-control"
                    withoutOffset={true}
                  />
                </>
              )}
            </Col>
            <Col xs={12}>
              <Field
                className="check"
                name="courrierChecked"
                type="checkbox"
                component="input"
                id="E-mail"
              />
              <label for="courrier">Courrier</label>
            </Col>
            {values.courrierChecked ? (
              <Col className={'quot-step-3-adress'}>
                <QuotationAdresse
                  errors={errors}
                  dirty={dirty}
                  setFieldValue={setFieldValue}
                  values={values}
                  withoutOffset={true}
                  quotationTns={values.tns}
                />
              </Col>
            ) : (
                ""
              )}
          </Row>
          {!values.tns && !values.agentTerritorial &&
            <Row>
              <Col xs={12} >
                <span>Mon numéro de téléphone</span>
              </Col>
              <Col xs={12}>
                <MgcPhoneField
                  dirty={dirty}
                  errors={errors}
                  name="telephone"
                  placeholder=""
                  withoutOffset
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </Col>
            </Row>
          }
          <Row>
            <Col xs={12}>
              <Field className="check" name="offreMgc" type="checkbox" component="input" id="offre-mgc" />
              <span>
                Si vous souhaitez recevoir, y compris par voie électronique des
                informations sur les offres de la MGC, les événements et les
                jeux-concours organisé par la MGC, cochez cette case.
              </span>
            </Col>
            <Col xs={12} className="m-top-10">
              <Field className="check" name="offrePartenaire" type="checkbox" component="input" id="offre-partenaire" />
              <span>
                Si vous souhaitez recevoir, y compris par voie électronique des
                informations sur les offres de nos partenaires proposées par la
                MGC(prévoyance...), cochez cette case.
              </span>
            </Col>
          </Row>
        </Container>
      </QuotationWizard.Step>
    )
  }
}

export default QuotationStep3
