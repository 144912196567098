import React from "react"
import { Row, Col } from "react-bootstrap"
import { Field } from "formik"
import MgcSelectField from "./mgcSelectField"
import MaskedInput from "react-text-mask";
class QuotationChildren extends React.Component {
  render() {
    const { tab, values, dirty, errors, tabRa ,setFieldValue } = this.props
    return (
      <div>
        <Row>
          <Col xs={11}>
            <label>Nombre d'enfants de moins d'1 an</label>
          </Col>
          <Col xs={11}>
            <Field
              name="nbEnfantsMoisn1an"
              type="text"
              className="form-control"
              render={({ field }) => {
                return (
                    <MaskedInput
                        mask={ [
                          /\d/,
                          ]}
                        {...field}
                        className="form-control"
                        placeholder={''}
                        guide={false}
                        id={"nbEnfantsMoisn1an"}
                    />
                )
              }}
            />
          </Col>
          <Col xs={11} className="m-bottom">
            <span>
              <em>Leur cotisation est gratuite jusqu'au mois de leur 1er
              anniversaire inclus.</em>
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={11}>
            <label>Nombre d'enfants entre 1 et 25 ans</label>
            <Field
              name="nbEnfantsPlus1an"
              component="select"
              className="form-control"
            >
              <option key="plop" value="null">
                - 0 enfant -
              </option>
              {tab.map(e => {
                return (
                  <option key={e.id} value={e.id}>
                    {e.libelle}
                  </option>
                )
              })}
            </Field>
          </Col>
        </Row>
        {values.nbEnfantsPlus1an > 1 ? <span>Régime d'assurance maladie du 1er enfant (le + âgé)</span> : ""}
        {values.nbEnfantsPlus1an == 1 ||
        values.nbEnfantsPlus1an == 2
        ? (
          <MgcSelectField
            dirty={dirty}
            name="regimeAssuranceMalEnfant1"
            errors={errors}
            firstOption=" - Régime d'assurance maladie – "
            className="form-control"
            table={tabRa}
            setFieldValue={setFieldValue}
          />
        ) : (
          ""
        )}
        {values.nbEnfantsPlus1an > 1 ? <span>Régime d'assurance maladie du 2ème enfant</span> : ""}
        {values.nbEnfantsPlus1an == 2 ? (
          <MgcSelectField
            dirty={dirty}
            name="regimeAssuranceMalEnfant2"
            errors={errors}
            firstOption=" - Régime d'assurance maladie – "
            className="form-control"
            table={tabRa}
            setFieldValue={setFieldValue}
          />
        ) : (
          ""
        )}
      </div>
    )
  }
}
export default QuotationChildren
