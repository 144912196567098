import * as React from "react"
import { Field } from "formik"
import MgcRadioToggleButtonGroup from "./mgcRadioToggleButtonGroup"
import { Col, Row } from "react-bootstrap"
import MgcRadioToggleButton from "./mgcRadioToggleButton"
import MgcSelectField from "./mgcSelectField"
import MgcNewDateField from "./mgcNewDateField";
import { hasOnlyNumbers } from "../utils/regexTests"

class QuotationAGCOLTER extends React.Component {

  componentDidMount() {
    if (!this.props.values.cpEmployeurAngentTerritotrial) {
      this.props.values.cpEmployeurAngentTerritotrial = ""
    }
  }

  dejaCouvert = ({ dejaCouvert }) => {
    //return !!dejaCouvert
    return dejaCouvert && (dejaCouvert === true || dejaCouvert === "true");
  }

  render() {
    const { errors, dirty, values, touched, setFieldValue, landing, value } = this.props
    return (
      <>
        <Row>
          <Col xs={11} className={landing && "landing-agentTerritoral"}>
            <Field
              type="text"
              name="employeurAgentTerritotrial"
              className="form-control"
              placeholder="Employeur"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={11}>
            <Field
              type="text"
              name="cpEmployeurAngentTerritotrial"
              className="form-control"
              placeholder="Code postal de l'employeur"
              onChange={e => {
                if (hasOnlyNumbers(e.target.value)) {
                  setFieldValue("cpEmployeurAngentTerritotrial", e.target.value)
                }
              }}
            />
          </Col>
        </Row>

        <Row className="question-y-n">
          <Col xs={12}>
            <span>Avez-vous déjà été couvert par une complémentaire santé labellisée ?</span>
          </Col>
          <Col xs={12} className="quotation-radio">
            <MgcRadioToggleButtonGroup
              id="deja-couvert"
              error={errors.dejaCouvert}
              touched={touched.dejaCouvert}
              withoutOffset={true}
              value={value}
              dirty={landing && true}
            >
              <Field
                component={MgcRadioToggleButton}
                name="dejaCouvert"
                id="true"
                label={"Oui"}
              />
              <Field
                component={MgcRadioToggleButton}
                name="dejaCouvert"
                id="false"
                label={"Non"}
              />
            </MgcRadioToggleButtonGroup>
          </Col>
        </Row>
        {this.dejaCouvert(values) &&
          <>
            <Row>
              <Col xs={12}>
                <MgcNewDateField
                  dirty={dirty}
                  errors={errors}
                  name="dateFinDernierContrat"
                  className="form-control form-row-step-2"
                  withoutOffset={true}
                  label={"Date de fin du dernier contrat labelisée"}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <MgcSelectField
                  dirty={dirty}
                  name="tauxMajorationDernierContrat"
                  errors={errors}
                  firstOption="- Taux de majoration du dernier contrat labellisé -"
                  className="form-control form-row-step-2"
                  setFieldValue={setFieldValue}
                  onChange={this.handleSituationProChange}
                  table={
                    [
                      { id: "0%", libelle: "0 %" },
                      { id: "2%", libelle: "2 %" },
                      { id: "4%", libelle: "4 %" }
                    ]
                  }
                />
              </Col>
            </Row>
          </>
        }
        {values.dejaCouvert && !this.dejaCouvert(values) &&
          <Row>
            <Col xs={12}>
              {/*            <MgcDateField
              dirty={dirty}
              errors={errors}
              name="dateEntreeFonctionPublique"
              className="form-control form-row-step-2"
              withoutOffset={true}
              label={"Date d'entrée dans la fonction publique"}
              setFieldValue={setFieldValue}
            />*/}
              <MgcNewDateField
                dirty={dirty}
                errors={errors}
                name="dateEntreeFonctionPublique"
                className="form-control form-row-step-2"
                withoutOffset={true}
                label={"Date d'entrée dans la fonction publique"}
                setFieldValue={setFieldValue}
                values={values}
              />
            </Col>
          </Row>
        }
      </>
    )
  }
}

export default QuotationAGCOLTER
