import React from "react"
import { Field } from "formik"
import { Container, Row, Col } from "react-bootstrap"
import QuotationWizard from "./quotationWizard"
import MgcSelectField from "./mgcSelectField"
import QuotationConjoint from "./quotationConjoint"
import QuotationChildren from "./quotationChildren"
import QuotationTNS from "./quotationTNS"
import QuotationAGCOLTER from "./quotationAGCOLTER"
import QuotationService from "../services/quotationService"
import QuotationChildrenTNS from "./quotationChildrenTNS"
import QuotationMapper from "../services/quotationMapper"
import QuotationBesoins from "./quotation/quotationBesoins"
import MgcNewDateField from "./mgcNewDateField"
import MgcPhoneField from "./mgcPhoneField"

class QuotationStep1 extends React.Component {
  sProFilter
  situationPro
  dateEffet
  besoinTNS
  values = this.props.values
  initialValues = this.props.initialValues
  state = {
    rA: this.props.rA,
    sP: this.props.sP,
    datesEffet: "",
    getDate: false,
    tns: false,
    agentTerritorial: false,
    questions: [],
    initRegimeAssuranceMal: "",
  }
  tabEnfantplus1An = [
    { id: 1, libelle: "1 enfant" },
    { id: 2, libelle: "2 enfants et plus" },
  ]

  componentDidUpdate(prevProps) {
    if (
      this.props.rA !== prevProps.rA &&
      !sessionStorage.getItem("regimeAss")
    ) {
      let regime = []
      this.props.rA
        ? this.props.rA.data.map(e => {
          e.id = e.code
          regime.push(e)
        })
        : regime.push({})
      this.setState({ rA: regime })
    }
    if (
      this.props.sP !== prevProps.sP &&
      !sessionStorage.getItem("situationPro")
    ) {
      this.setState({ sP: this.props.sP })
    }
  }

  changeStateDateEffet = tab => {
    if (tab) {
      this.setState({ datesEffet: tab })
      if (this.props.values.debutContrat) {
        this.onChangeDateEffet(this.props.values.debutContrat)
      }
    }
  }

  checkTNS(val) {
    const { setFieldValue } = this.props
    if (this.state.sP[val.regimeAssuranceMal]) {
      let tns = this.state.sP[val.regimeAssuranceMal].filter(
        e => e.id === val.situationPro
      )[0]
        ? this.state.sP[val.regimeAssuranceMal].filter(
          e => e.id === val.situationPro
        )[0].tns
        : false
      this.setState({ tns: tns })
      setFieldValue("tns", tns)
      val.tns = tns
      if (this.tns !== true) {
        let agentTerritorial = this.state.sP[val.regimeAssuranceMal].filter(
          e => e.id === val.situationPro
        )[0]
          ? this.state.sP[val.regimeAssuranceMal].filter(
            e => e.id === val.situationPro
          )[0].agentTerritorial
          : false
        this.setState({ agentTerritorial: agentTerritorial })
        setFieldValue("agentTerritorial", agentTerritorial)
        val.agentTerritorial = agentTerritorial
      }
    }
  }

  handleRegimeAssuranceMalChange = value => {
    const { setFieldValue, values } = this.props
    setFieldValue("regimeAssuranceMal", value)
    setFieldValue("situationPro", "")
    this.setState({ tns: false, agentTerritorial: false })
    if (values.dateNaissance && value && values.situationPro.length > 0) {
      QuotationService.getDateEffet(
        values.dateNaissance,
        value,
        values.situationPro,
        values.childrenChecked
      ).then(result => {
        this.changeStateDateEffet(result)
      })
    }
  }

  handleChildrenCheckedlChange(value) {
    const { values } = this.props
    if (
      values.dateNaissance &&
      values.regimeAssuranceMal.length > 0 &&
      values.situationPro.length > 0
    ) {
      QuotationService.getDateEffet(
        values.dateNaissance,
        values.regimeAssuranceMal,
        values.situationPro,
        values.childrenChecked
      ).then(result => {
        this.changeStateDateEffet(result)
      })
    }
  }

  handleSituationProChange = value => {
    const { setFieldValue, values } = this.props
    setFieldValue("situationPro", value)
    if (values.dateNaissance && values.regimeAssuranceMal.length > 0 && value) {
      QuotationService.getDateEffet(
        values.dateNaissance,
        values.regimeAssuranceMal,
        value,
        values.childrenChecked
      ).then(result => {
        this.changeStateDateEffet(result)
      })
    }
  }
  handleDateNaissanceChange = value => {
    const { setFieldValue, values } = this.props
    setFieldValue("dateNaissance", value)
    if (
      value &&
      values.regimeAssuranceMal.length > 0 &&
      values.situationPro.length > 0
    ) {
      QuotationService.getDateEffet(
        value,
        values.regimeAssuranceMal,
        values.situationPro,
        values.childrenChecked
      ).then(result => {
        this.changeStateDateEffet(result)
      })
    }
  }

  onChangeDateEffet = dateEffet => {
    const { values, setFieldValue } = this.props
    this.setState({ questions: [] })
    if (!!values.situationPro) {
      const devis = QuotationMapper.toDevisDTO(values, dateEffet)
      QuotationService.getDDAQuestions(devis).then(r => {
        this.setState({ questions: r.data })
        if (
          !!r.data.find(question => {
            return question.path === "couvertureZen"
          })
        ) {
          setFieldValue("zen", true)
        }
      })
    }
  }

  questionsLoaded = questions => {
    return questions && questions.length > 0
  }

  componentDidMount() {
    const { validateForm, onContinue, values } = this.props
    if (
      values.regimeAssuranceMal &&
      values.situationPro &&
      values.dateNaissance
    ) {
      this.checkTNS(values)
      QuotationService.getDateEffet(
        values.dateNaissance,
        values.regimeAssuranceMal,
        values.situationPro,
        values.childrenChecked
      ).then(result => {
        this.changeStateDateEffet(result)
      })
    }
    if (sessionStorage.getItem("devisStepOne")) {
      onContinue(JSON.parse(sessionStorage.getItem("devisStepOne")))
    }
    if (sessionStorage.getItem("regimeAss")) {
      this.setState({ rA: JSON.parse(sessionStorage.getItem("regimeAss")) })
    }
    if (sessionStorage.getItem("situationPro")) {
      this.setState({ sP: JSON.parse(sessionStorage.getItem("situationPro")) })
    }
    validateForm()
    if (this.state.rA.headers && !sessionStorage.getItem("regimeAss")) {
      this.setState({ rA: this.state.rA.data })
    }
  }

  render() {
    const { errors, dirty, values, touched, setFieldValue } = this.props
    this.situationPro = this.props.sP
    return (
      <QuotationWizard.Step>
        <Container className="quotation">
          <h2>PERSONNE(S) À COUVRIR</h2>
          <h3>Adhérent principal</h3>
          <Row>
            {errors.dateNaissance && values.dateNaissance ? (
              <Col xs={11} className="alert-majeur">
                {" "}
                <span className="">
                  Il n'est pas possible d'adhérer avant 18 ans
                </span>
              </Col>
            ) : (
                ""
              )}
          </Row>
          <Row>
            <Col xs={12}>
              <MgcNewDateField
                dirty={dirty}
                errors={errors}
                name="dateNaissance"
                className="form-control form-row-step-2"
                withoutOffset={true}
                label={"Date de naissance"}
                setFieldValue={setFieldValue}
                values={values}
                onChange={this.handleDateNaissanceChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <MgcSelectField
                dirty={dirty}
                name="regimeAssuranceMal"
                errors={errors}
                firstOption="- Régime d'assurance maladie -"
                className="form-control form-row-step-2"
                onChange={this.handleRegimeAssuranceMalChange}
                table={this.state.rA.data ? this.state.rA.data : this.state.rA}
                setFieldValue={setFieldValue}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              onClick={() => {
                this.checkTNS(values)
              }}
            >
              <MgcSelectField
                dirty={dirty}
                name="situationPro"
                errors={errors}
                firstOption="- Situation professionnelle -"
                className="form-control form-row-step-2"
                setFieldValue={setFieldValue}
                assusranceMalUnselected={
                  !values.regimeAssuranceMal
                }
                onChange={this.handleSituationProChange}
                table={
                  this.state.sP[values.regimeAssuranceMal]
                    ? this.state.sP[values.regimeAssuranceMal]
                    : [{ id: 999, libelle: "" }]
                }
              />
            </Col>
          </Row>
          <Row>
            {this.state.agentTerritorial ? (
              <Col xs={12}>
                <QuotationAGCOLTER
                  errors={errors}
                  dirty={dirty}
                  values={values}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </Col>
            ) : (
                ""
              )}
            {this.state.tns ? (
              <Col xs={12}>
                <QuotationTNS
                  errors={errors}
                  dirty={dirty}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  notOffset={true}
                />
              </Col>
            ) : (
                ""
              )}
          </Row>
          <h3>Autre(s) bénéficiaire(s)</h3>
          <Row>
            <Col>
              <Field
                className="check "
                name="conjointChecked"
                type="checkbox"
                component="input"
                id="conjoint"
                checked={values.conjointChecked}
              />
              <label for="conjoint">Mon conjoint</label>
            </Col>
          </Row>
          {values.conjointChecked ? (
            <QuotationConjoint
              dirty={dirty}
              errors={errors}
              tab1={this.state.rA.data ? this.state.rA.data : this.state.rA}
              tab2={this.state.sP}
              values={values}
              setFieldValue={setFieldValue}
            />
          ) : (
              ""
            )}
          <Row>
            <Col>
              <Field
                className="check"
                name="childrenChecked"
                type="checkbox"
                component="input"
                id="children"
                onClick={() => this.handleChildrenCheckedlChange(values)}
                checked={values.childrenChecked}
              />
              <label for="children">Mes enfants</label>
            </Col>
          </Row>
          {values.childrenChecked ? (
            !this.state.tns ? (
              <QuotationChildren
                tab={this.tabEnfantplus1An}
                tabRa={this.state.rA.data ? this.state.rA.data : this.state.rA}
                values={values}
                dirty={dirty}
                errors={errors}
                setFieldValue={setFieldValue}
              />
            ) : (
                <QuotationChildrenTNS
                  tab={this.tabEnfantplus1An}
                  tabRa={this.state.rA.data ? this.state.rA.data : this.state.rA}
                  values={values}
                  dirty={dirty}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              )
          ) : (
              ""
            )}
          <h2 className="p-top-50">DATE DE PRISE D'EFFET DU CONTRAT</h2>
          <Row>
            <Col xs={12}>
              {/*  <MgcDateField dirty={dirty} errors={errors} name="debutContrat" className="form-control" /> */}
              <MgcSelectField
                setFieldValue={setFieldValue}
                dirty={dirty}
                errors={errors}
                name="debutContrat"
                firstOption=""
                className="form-control"
                table={
                  this.state.datesEffet
                    ? this.state.datesEffet
                    : [{ id: "", libelle: "" }]
                }
                onChange={this.onChangeDateEffet}
              />
            </Col>
          </Row>

          <h2 hidden={!this.questionsLoaded(this.state.questions)}>BESOINS</h2>
          <h3 hidden={!this.questionsLoaded(this.state.questions)}>
            Vous ou votre conjoint
          </h3>

          <QuotationBesoins
            errors={errors}
            touched={touched}
            questions={this.state.questions}
          />

          <h3 hidden={!this.state.tns && !this.state.agentTerritorial}>
            Pour réaliser un devoir de conseil avisé, un conseiller MGC peut me
            joindre au :
          </h3>
          <MgcPhoneField
            dirty={dirty}
            errors={errors}
            name="telephone"
            placeholder="Téléphone"
            hidden={!this.state.tns && !this.state.agentTerritorial}
            values={values}
            setFieldValue={setFieldValue}
            withoutOffset
          />
          <Row>
            <Col md={11} className="text-end-quotation-step1">
              <span>
                Si vous n’avez pas pu exprimer l’ensemble de vos besoins via ce
                questionnaire, nous vous invitons à contacter l’un de nos
                conseillers mutualistes. Il pourra prendre en compte vos besoins
                et exigences pour vous apporter un conseil avisé. Nos
                conseillers reçoivent une rémunération portant une part fixe et
                une part variable n'excédant pas 15% de leur rémunération et
                n'étant pas basée sur le type de produit vendu.
              </span>
            </Col>
          </Row>
        </Container>
      </QuotationWizard.Step>
    )
  }
}

export default QuotationStep1
