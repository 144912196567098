import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import QuotationWizard from "./quotationWizard"
import GAnalyticsService from "../services/gAnalyticsService"
class QuotationStep4 extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
    const { codeGamme } = this.props.values
    if (codeGamme) {
      GAnalyticsService.sendEvent("Devis santé", "Envoi de devis")
    }
  }
  render() {
    return (
      <QuotationWizard.Step>
        <Container className="quotation-step-4">
          <h3>VOTRE DEMANDE A ÉTÉ PRISE EN COMPTE !</h3>
          <Row>
            <Col xs={12}>
              <p>
                Merci pour cette demande de devis et de l'intérêt que vous portez à la mutuelle MGC. 
                Votre demande a bien été prise en compte et vous recevrez votre devis dans les meilleurs délais.
              </p>
              <p>
                Si vous désirez de plus amples informations, n'hésitez pas à nous contacter par e-mail (via la page contact) 
                ou par téléphone au 01 40 78 57 10 (du lundi au vendredi de 9h à 12h30 et 13h30 à 17h) 
                ou au 32 80 06 pour les salariés SNCF, ou sur simple demande de rappel gratuit immédiat ou différé.
              </p>
              <p>
                Nous serons ravis de vous fournir de plus amples informations et de répondre à toutes vos questions.
              </p>
            </Col>
          </Row>
        </Container>
      </QuotationWizard.Step>
    )
  }
}

export default QuotationStep4
