import * as React from "react"
import QuotationWizard from "./quotationWizard"
import QuotationStep1 from "./quotationStep1"
import QuotationStep2 from "./quotationStep2"
import QuotationStep3 from "./quotationStep3"
import QuotationStep4 from "./quotationStep4"
import Axios from "axios"
import QuotationALert from "./quotationAlert"
import Spinner from "react-bootstrap/Spinner"
import { MgcgestionTokenService } from "../services/mgcgestionTokenService"

class Quotation extends React.Component {
    componentDidMount() {
        if (sessionStorage.getItem("devisStepOne")) {
            this.setState({ waiting: true })
            this.setState({initValues: JSON.parse(sessionStorage.getItem("devisStepOne"))})
        }
        let URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/regime-assurances/actif`
/*        URL ="http://localhost:8080/api/public/regime-assurances/actif"*/
        Axios.get(URL)
            .then(result => {
                this.setState({ regimeAssurance: result })
            })
            .then(() => {
                URL = `${
                    process.env.GATSBY_MGCGESTION_API_URL
                    }/public/situation-professionnelles/actives`
/*                URL= "http://localhost:8080/api/public/situation-professionnelles/actives"*/
                Axios.get(URL).then(result => {
                    let situationPro = []
                    for (let o = 0; o < this.state.regimeAssurance.data.length; o++) {
                        situationPro[this.state.regimeAssurance.data[o].code] = []
                        for (let i = 0; i < result.data.length; i++) {
                            for (let j = 0; j < result.data[i].regimeAssurances.length; j++) {
                                if (
                                    result.data[i].regimeAssurances[j].code ==
                                    this.state.regimeAssurance.data[o].id
                                ) {
                                    result.data[i].id = result.data[i].code
                                    situationPro[this.state.regimeAssurance.data[o].code].push(
                                        result.data[i]
                                    )
                                }
                            }
                        }
                    }
                    this.setState({ situationProfessionelle: situationPro })
                })
            })
    }
    constructor(props) {
        super(props)
        this.state = {
            regimeAssurance: [1, 2],
            situationProfessionelle: [1, 2],
            waiting: false,
            initValues: {}
        }
    }
    stepBack =() =>{
        this.setState({waiting:false})
    }
    render() {
        return (
          <QuotationWizard initialValues={this.state.initValues} thisStep={this.props.thisStep}>
              {this.state.waiting ?
                <div className={'spinner-step-one'}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>:
                <QuotationStep1
                  rA={ this.state.regimeAssurance }
                  sP={  this.state.situationProfessionelle  }
                /> }
              <QuotationStep2 rA={this.state.regimeAssurance}  waiting={this.stepBack}/>
              <QuotationStep3 />
              <QuotationStep4 />
              <QuotationALert />
          </QuotationWizard>
        )
    }
}
export default Quotation
