import React, { globalBroadcaster } from "react"
import Layout from "../components/layout"
import Quotation from "../components/quotation"

class QuotationPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      regimeAssurance: null,
      situationProfessionelle: null,
      thisStep: 0,
    }
  }

  toKnowStep = () => {
    this.setState({ thisStep: this.state.step++ })
  }

  render() {
    return (
      <Layout
        titleSeo="Devis mutuelle santé : immédiat, gratuit et sans engagement"
        keywordsSeo={["Devis", "mutuelle", "santé"]}
        canonical={this.props.pageContext.canonicalUrl}
        description={
          "Devis mutuelle santé en trois clics : comparez facilement les tarifs afin de choisir la complémentaire santé adaptée à vos besoins et à votre budget."
        }
      >
        <section className="pageHeader">
          <div className="pageHeaderContainer">
            <nav>
              <ul className="breadcrumb">
                <li className="breadcrumbItem">
                  <a
                    href="/"
                    className="breadcrumbItemLink breadcrumbItemLinkHome"
                  >
                    <i className="icon-mgc" />
                  </a>
                </li>
                <li className="breadcrumbItem">
                  <span className="breadcrumbItemLink breadcrumbItemLinkMiddle">
                    Devis santé
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </section>
        <section className="pageHeader callback">
          <div className="quotation-title">
            <h1 className="pageHeaderTitle ">DEVIS MUTUELLE SANTÉ</h1>
          </div>
        </section>
        {this.state.thisStep === 0 ? (
          <div className="header-quotation">
            <div className="subheader-quotation">
              <h4>2 mois offerts *</h4>
              <span>jusqu'au 31 mai 2024</span>
            </div>
            <div>
              <a href="/mutuelle-sante/offres-promotionnelles/">
                Voir les conditions de l'offre
              </a>
            </div>
          </div>
        ) : (
          ""
        )}
        <section
          className={
            this.state.thisStep === 0
              ? "quotation-body-step-1"
              : "quotation-body-other-step"
          }
        >
          <div className="container-quotation">
            <div className="quotation-form col-xs-12">
              <div className="header-form-img">
                {/* <img src={quotationIcon} alt="" /> */}
              </div>
              <div classsName="row">
                <div>
                  <Quotation props={this.props} thisStep={this.toKnowStep} />
                </div>
              </div>
            </div>
            <div className="quotation-footer">
              <h6>
                Le devis mutuelle complémentaire santé MGC sans engagement
              </h6>
              <span>
                Vous souhaitez faire une simulation d'assurance santé afin de
                choisir la meilleure complémentaire santé ? La MGC vous permet
                de comparer ses garanties santé pour faire le meilleur choix en
                prenant en compte vos besoins et votre budget.
              </span>
              <h6>Votre devis santé en ligne en quelques clics</h6>
              <span>
                Les garanties santé les plus adaptées à votre situation sont
                tarifées directement et affichées à l'écran. Seules les
                informations nécessaires au calcul de votre devis sont à
                renseigner. Si vous souhaitez un devis pour d'autres garanties,
                n'hésitez pas à nous contacter.
              </span>
              <h6>
                Votre devis santé par téléphone, du lundi au vendredi de 9h à
                18h00.
              </h6>
              <span>
                Les conseillers MGC sont à votre disposition au 01 40 78 57 10,
                prix d'un appel local (32 80 06 depuis un poste SNCF), pour
                simuler votre cotisation santé et vous conseiller sur le choix
                de la formule la plus adaptée à votre situation.
              </span>
              <h6>Votre devis santé en agence</h6>
              <span>
                Pour rencontrer un conseiller MGC et établir un devis santé
                personnalisé, rendez-vous en agence ! NB: En cas de besoin, vous
                pouvez demander une aide pour réduire votre cotisation. Voir les
                aides existantes et les conditions pour en bénéficier.
              </span>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default QuotationPage
