import React from "react"
import { Row, Col } from "react-bootstrap"
import MgcSelectField from "./mgcSelectField"
import MgcDateField from "./mgcDateField"
import MgcNewDateField from "./mgcNewDateField";
class QuotationConjoint extends React.Component {
  render() {
    const { tab1, tab2, errors, dirty, values, setFieldValue } = this.props
    return (
      <div>
        <Row>
          { errors.dateNaissanceConjoint && values.dateNaissanceConjoint.length >6 ? <Col xs={11} className="alert-majeur"> <span className="">Il n'est pas possible d'adhérer avant 18 ans</span></Col>:''}
          </Row>
        <Row>
          <Col xs={12}>
              <MgcNewDateField
                  dirty={dirty}
                  errors={errors}
                  name="dateNaissanceConjoint"
                  className="form-control"
                  withoutOffset={true}
                  label={"Date de naissance"}
                  setFieldValue={setFieldValue}
                  values={values}
              />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={'regimeAss'}>
            <MgcSelectField
              dirty={dirty}
              name="regimeAssuranceMalConjoint"
              errors={errors}
              firstOption="- Régime d'assurance maladie -"
              className={"form-control"}
              setFieldValue={setFieldValue}
              table={tab1}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <MgcSelectField
              dirty={dirty}
              assusranceMalUnselected={
                !values.regimeAssuranceMalConjoint
              }
              name="situationProConjoint"
              errors={errors}
              firstOption="- Situation professionnelle -"
              className="form-control"
              setFieldValue={setFieldValue}
              table={
                tab2[values.regimeAssuranceMalConjoint]
                  ? tab2[values.regimeAssuranceMalConjoint]
                  : []
              }
            />
          </Col>
        </Row>
      </div>
    )
  }
}
export default QuotationConjoint
