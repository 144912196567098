import * as React from "react"
import MgcTextField from "./mgcTextField"
import { Field } from "formik"
import MgcCpVille from "./mgcCpVille"
import MgcAutoComplete from "./mgcAutocomplete"
import Axios from "axios"
import PropTypes from "prop-types"
import MaskedInput from "react-text-mask"
import { maskStreetNumber } from "../services/global/fieldMasks"

class QuotationAdresse extends React.Component {
 
  constructor(props) {
    super(props)
    this.state = {
      numeroLatinList: [],
      typeVoieList: [],
      paysList: [],
      filteredPaysList: [],
      aelCountryWarningHidden: true,
    }
    this.handlePaysChange = this.handlePaysChange.bind(this)
    this.setAelCountryWarningHidden = this.setAelCountryWarningHidden.bind(this)
    this.handlePaysSelect = this.handlePaysSelect.bind(this)
  }

  componentDidMount() {
    let URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/numero-latins`
/*        URL = "http://localhost:8080/api/public/numero-latins"*/
    Axios.get(URL).then(result => {
      this.setState({ numeroLatinList: result.data })
    })
    URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/type-voies?size=999&sort=libelle,asc`
/*        URL = "http://localhost:8080/api/public/type-voies"*/
    Axios.get(URL).then(result => {
      this.setState({ typeVoieList: result.data })
    })
    URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/pays/autocomplete`
/*        URL = "http://localhost:8080/api/public/pays/autocomplete"*/
    Axios.get(URL).then(result => {
      this.setState({ paysList: result.data })
    })

    const { quotationTns, setFieldValue, values } = this.props
    if (quotationTns) {
      setFieldValue("adresseCodePostal", values.codePostalTNS)
    }
  }

  handlePaysChange(value) {
    let newPaysList = []
    if (value && value.length && value.length >= 2) {
      newPaysList = this.state.paysList.filter(p => {
        return p.libelle.toUpperCase().startsWith(value.toUpperCase())
      })
    } else if (!value) {
    }
    this.setState({ filteredPaysList: newPaysList })
  }

  handlePaysSelect(value) {
    if (this.props.validCountryAel) {
      this.setAelCountryWarningHidden(value)
    }
  }
  setAelCountryWarningHidden(value) {
    const { paysList } = this.state
    const { setFieldValue } = this.props
    if (value) {
      const validAel = paysList.some(p => p.libelle === value && p.ael === true)
      setFieldValue("countryValidAel", validAel)
      this.setState({
        aelCountryWarningHidden: validAel,
      })
    }
  }

  render() {
    const {
      errors,
      onChange,
      dirty,
      setFieldValue,
      values,
      withoutOffset,
      needLibelleOfTypeVoie,
      withoutCountry,
    } = this.props

    return (
      <>
        <div className={withoutOffset ? "" : "col-11"}>
          <div className={withoutOffset ? "" : "offset-1"}>
            <MgcTextField
              dirty={dirty}
              errors={errors}
              name="appartement"
              placeholder={"N° Appt/Esc/Couloir/Etage"}
              withoutOffset={withoutOffset}
              maxLength={38}
            />
            <MgcTextField
              dirty={dirty}
              errors={errors}
              name="batiment"
              placeholder={"Entrée/Tour/Immeuble/Bât"}
              withoutOffset={withoutOffset}
              maxLength={38}
            />
          </div>
          <div className="form-row margin-bottom-15">
            <div className={withoutOffset ? "" : "col-1"} />
            <div className={withoutOffset ? "col-3" : "col-2"}>
              <Field
                name="adresseNumero"
                render={({ field }) => {
                  return (
                    <MaskedInput
                      mask={maskStreetNumber}
                      {...field}
                      className="form-control"
                      placeholder="N°"
                      guide={false}
                      id="adresseNumero"
                    />
                  )
                }}
              />
            </div>
            <div className="col-4">
              <Field
                name="adresseNumeroLatin"
                component="select"
                className="form-control"
              >
                <option value="">- N° latin -</option>
                {this.state.numeroLatinList.map(e => {
                  return (
                    <option value={e.id} key={e.id}>
                      {e.libelle}
                    </option>
                  )
                })}
              </Field>
            </div>
            <div className="col-4">
              <Field
                type="text"
                name="adresseComplementAlpha"
                className="form-control complementAlpha"
                placeholder="Complément alpha"
                maxlength="1"
                onChange={e => {
                  setFieldValue(
                    "adresseComplementAlpha",
                    e.target.value.toUpperCase()
                  )
                }}
              />
            </div>
            <div className="col-1">
              <span
                className={`${
                  errors["adresseNumero"] ||
                  errors["adresseNumeroLatin"] ||
                  errors["adresseComplementAlpha"] ||
                  !dirty
                    ? "fa-times text-danger"
                    : "fa-check text-success"
                } fa-2x fa`}
              />
            </div>
          </div>
          <div className="form-row margin-bottom-15">
            <div className={withoutOffset ? "" : "col-1"} />
            <div className={withoutOffset ? "col-5" : "col-4"}>
              <Field
                name="adresseTypeVoie"
                component="select"
                className="form-control"
              >
                <option value="">- Type de voie -</option>
                {this.state.typeVoieList.map(e => {
                  return (
                    <option
                      value={needLibelleOfTypeVoie ? e.libelle : e.id}
                      key={e.libelle}
                    >
                      {e.libelle}
                    </option>
                  )
                })}
              </Field>
            </div>
            <div className="col-6">
              <Field
                type="text"
                name="adresseVoie"
                className="form-control"
                placeholder="Nom de la voie"
                maxLength={25}
              />
            </div>
            <div className="col-1">
              <span
                className={`${
                  errors["adresseTypeVoie"] || errors["adresseVoie"] || !dirty
                    ? "fa fa-times text-danger"
                    : "fa fa-check text-success"
                } fa-2x`}
              />
            </div>
          </div>
          <div className={withoutOffset ? "" : "offset-1"}>
            <MgcTextField
              errors={errors}
              dirty={dirty}
              name="adresseLieuDit"
              placeholder="Lieu-dit"
              withoutOffset={withoutOffset}
              maxLength={38}
            />
          </div>
          <MgcCpVille
            nameCP="adresseCodePostal"
            nameVille="adresseVille"
            errors={errors}
            dirty={dirty}
            setFieldValue={setFieldValue}
            values={values}
            placeholderCp="Code postal"
            withoutOffset={withoutOffset}
            initValue={values.tns ? values.codePostalTNS : false}
            disabled={values.tns}
          />
          {withoutCountry ? (
            ""
          ) : (
            <MgcAutoComplete
              className={"pays"}
              withoutOffset={withoutOffset}
              dirty={dirty}
              errors={errors}
              placeholder="Pays"
              name="adressePays"
              values={values}
              items={this.state.filteredPaysList}
              onChange={this.handlePaysChange}
              labelField="libelle"
              valueField="libelle"
              setFieldValue={setFieldValue}
              onSelect={this.handlePaysSelect}
              classe={"quot-step-3"}
            />
          )}
        </div>
      </>
    )
  }
}

QuotationAdresse.propTypes = {
  errors: PropTypes.object,
  dirty: PropTypes.object,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  validCountryAel: PropTypes.bool,
}
export default QuotationAdresse
