import React from "react"
import { Row, Col } from "react-bootstrap"
import { Field } from "formik"
import MgcSelectField from "./mgcSelectField"
class QuotationChildrenTNS extends React.Component {
  render() {
    const { tab, values, dirty, errors, tabRa, setFieldValue } = this.props
    return (
      <div>
        <Row>
          <Col xs={11}>
            <label>Nombre d'enfants</label>
            <Field
              name="nbEnfantsPlus1an"
              component="select"
              className="form-control"
            >
              <option key="plop" value="null">
                - 0 enfant -
              </option>
              {tab.map(e => {
                return (
                  <option key={e.id} value={e.id}>
                    {e.libelle}
                  </option>
                )
              })}
            </Field>
          </Col>
            <Col xs={1} className={"quotation-childrenTNS"}>
                <span
                    className={`${
                        !values.nbEnfantsPlus1an
                            ? "fa fa-times text-danger"
                            : "fa fa-check text-success"
                        } fa-2x fa`}
                />
            </Col>
        </Row>
        {values.nbEnfantsPlus1an > 1 ? (
          <span>Régime d'assurance maladie du 1er enfant (le + âgé)</span>
        ) : (
          ""
        )}
        {values.nbEnfantsPlus1an == 1 || values.nbEnfantsPlus1an == 2 ? (
          <MgcSelectField
            dirty={dirty}
            name="regimeAssuranceMalEnfant1"
            errors={errors}
            firstOption=" - Régime d'assurance maladie – "
            className="form-control"
            table={tabRa}
            setFieldValue={setFieldValue}
          />
        ) : (
          ""
        )}
        {values.nbEnfantsPlus1an > 1 ? (
          <span>Régime d'assurance maladie du 2ème enfant</span>
        ) : (
          ""
        )}
        {values.nbEnfantsPlus1an == 2 ? (
          <MgcSelectField
            dirty={dirty}
            name="regimeAssuranceMalEnfant2"
            errors={errors}
            firstOption=" - Régime d'assurance maladie – "
            className="form-control"
            table={tabRa}
            setFieldValue={setFieldValue}
          />
        ) : (
          ""
        )}
      </div>
    )
  }
}

export default QuotationChildrenTNS
