import React from "react"
import PropTypes from "prop-types"
import { Col, Container, Row } from "react-bootstrap"
import MgcRadioToggleButtonGroup from "../mgcRadioToggleButtonGroup"
import { Field } from "formik"
import MgcRadioToggleButton from "../mgcRadioToggleButton"
import AelStep2BesoinQuestion from "../aelStep2BesoinQuestion"

const QuotationBesoins = ({errors, touched, questions}) => {

  // cas particulier pour l question couverture Zen ce n'est pas un booleen
  const zenQuestion = questions.find(q => {return q.path === 'couvertureZen'})
  const filteredQuestions = questions.filter(q => {return q.path !== 'couvertureZen'})
  return (
   <>
     {zenQuestion &&
     <Row className="question-y-n">
       <Col xs={12}>
              <span dangerouslySetInnerHTML={{ __html: zenQuestion.labelQuestion}} />
       </Col>
       <Col xs={12} className="quotation-radio">
         <MgcRadioToggleButtonGroup
           id={zenQuestion.path}
           error={errors[`${zenQuestion.path}`]}
           touched={touched[`${zenQuestion.path}`]}
           withoutOffset={true}
         >
           <Field
             component={MgcRadioToggleButton}
             name={zenQuestion.path}
             id="TARIF"
             label={"Tarif"}
           />
           <Field
             component={MgcRadioToggleButton}
             name={zenQuestion.path}
             id="NIVEAU_PRESTA"
             label={"Niveau de prestation"}
           />
         </MgcRadioToggleButtonGroup>
       </Col>
     </Row>
     }
     {filteredQuestions && filteredQuestions.filter(q => {return q.labelQuestion && q.path && q.path !== 'couvertureZen'}).map(question => {
       return (
         <Row className="question-y-n">
           <Col xs={12}>
               <span dangerouslySetInnerHTML={{ __html: question.labelQuestion}} />
           </Col>
           <Col xs={12} className="quotation-radio">
             <MgcRadioToggleButtonGroup
               id={question.path}
               error={errors[`${question.path}`]}
               touched={touched[`${question.path}`]}
               withoutOffset={true}
             >
               <Field
                 component={MgcRadioToggleButton}
                 name={question.path}
                 id="true"
                 label={"Oui"}
               />
               <Field
                 component={MgcRadioToggleButton}
                 name={question.path}
                 id="false"
                 label={"Non"}
               />
             </MgcRadioToggleButtonGroup>
           </Col>
         </Row>
       )
     })}
   </>
  )
}

QuotationBesoins.propTypes = {

}

export default QuotationBesoins