import * as React from "react"
import MgcCpVille from "./mgcCpVille"
import MgcRadioToggleButtonGroup from "./mgcRadioToggleButtonGroup"
import { Field } from "formik"
import MgcRadioToggleButton from "./mgcRadioToggleButton"
import { Row, Col } from "react-bootstrap"
class QuotationTNS extends React.Component {
  render() {
    const {
      errors,
      dirty,
      setFieldValue,
      values,
      touched,
      notOffset,
        landing
    } = this.props
    return (
      <div>
        <MgcCpVille
          nameCP="codePostalTNS"
          nameVille="villeTNS"
          notOffset={notOffset}
          errors={errors}
          dirty={dirty}
          setFieldValue={setFieldValue}
          values={values}
          placeholderCp="Code postal"
          withoutOffset={true}
        />
        <Row>
          <Col xs={11} className={landing && 'landing-agentTerritoral'}>
            <Field
              type="text"
              name="nomEntrepriseTNS"
              className="form-control"
              placeholder="Entreprise"
            />
          </Col>
        </Row>
        <div className="col-12" className="quotation-radio">
          <label>Avez-vous des salariés ?</label>
          <MgcRadioToggleButtonGroup
            id="salaries"
            error={errors.salariesTNS}
            touched={touched.salariesTNS}
            withoutOffset={true}
          >
            <Field
              component={MgcRadioToggleButton}
              name="salariesTNS"
              id="true"
              label={"Oui"}
            />
            <Field
              component={MgcRadioToggleButton}
              name="salariesTNS"
              id="false"
              label={"Non"}
            />
          </MgcRadioToggleButtonGroup>
        </div>
      </div>
    )
  }
}

export default QuotationTNS
