import MgcRadioToggleButtonGroup from "./mgcRadioToggleButtonGroup"
import { Field } from "formik"
import * as React from "react"
import MgcRadioToggleButton from "./mgcRadioToggleButton"

const AelStep2BesoinQuestion = ({values, errors, touched, question, name, disabled}) => (
<>
  <div className="form-row">
    <span className="col-10 offset-1" dangerouslySetInnerHTML={{ __html: question}} />
  </div>
  <MgcRadioToggleButtonGroup id={`besoins.${name}`} value={values[name]}
                             error={errors[name]}
                             touched={touched[name]}>
    <Field component={MgcRadioToggleButton} name={`besoins.${name}`} id="true"
           label={"Oui"} disabled={disabled}/>
    <Field component={MgcRadioToggleButton} name={`besoins.${name}`} id="false"
           label={"Non"} disabled={disabled}/>
  </MgcRadioToggleButtonGroup>
</>
);


export default AelStep2BesoinQuestion;
